<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <router-link :to="newPlatformRoute">
          <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
            + New platform
          </sun-button>
        </router-link>
      </template>

      <template #content>
        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          :loading="isLoading"
          class="mt-2"
          @sort="onSortTable"
        >
          <template #[`col.provider`]="{ item }">
            <sun-data-table-cell>
              {{ item.platformProvider.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.id" :color="item.status.color" />
            </sun-data-table-cell>
          </template>

          <template #[`col.updated`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip v-if="item.lastImport" :date="item.lastImport" class="inline-flex" />
              <p v-else>-</p>
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          v-show="!isLoading"
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>

    <platform-reload-modal
      v-if="showReloadModal"
      :platform-id="selectedId"
      :reload-fn="reloadPlatformData"
      @cancel="closeReloadModal"
      @reload="updatePlatformStatus"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import { PLATFORM_RESOURCE } from '@/services/keys';
import { ThirdPartyApi } from '@/services/api/ThirdPartyApi';
import { getConnectedPlatforms, reloadPlatformData } from '@/services/modules/ThirdParty/platforms';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { create as routerCreatePlatform } from '@/router/private/modules/thirdParty/platforms/connection/create';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { isAdminUser } from '@/utils/user/isAdminUser';
import { USER } from '@/store/modules/auth/keys';
import { CONFIG } from './config';
import { MIN_HOURS_SINCE_LAST_IMPORT } from '@/entities/dashboardSSP/Platform';

export default {
  name: 'PlatformList',
  components: {
    AsterixSection,
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    PlatformReloadModal: () => import('@/components/organisms/modules/dashboardSSP/platform/PlatformReloadModal'),
  },
  mixins: [activeClientMixin, indexMixin],
  data: () => ({
    isLoading: false,
    headers: CONFIG.headers,
    items: [],
    totalPages: 0,
    currentApi: new ThirdPartyApi(),
    resource: PLATFORM_RESOURCE,
    showReloadModal: false,
    selectedId: null,
    actions: [{ name: 'Reload' }],
    platformIdToReimport: null,
  }),
  computed: {
    ...mapGetters({
      user: USER,
    }),
    newPlatformRoute() {
      return { name: routerCreatePlatform.name };
    },
    isAdminUser() {
      return isAdminUser(this.user);
    },
  },
  created() {
    this.getTableItems();
  },
  methods: {
    reloadPlatformData,
    ...mapActions({ createToast: CREATE_TOAST }),
    onActionClick(event, item) {
      if (event.name === 'Reload') {
        this.openReloadModal(item);
      }
    },
    async getTableItems() {
      this.isLoading = true;
      const params = new QueryParamsBuilder();
      params.addFilter('client.id', this.activeClient.id);
      const { data, isCancel } = await this.getItemsFromAPI(params => getConnectedPlatforms(params));
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },

    openReloadModal(platform) {
      if (!platform.canReloadByStatusRestriction()) {
        this.createToast(Toast.error('Oops! Something went wrong', 'You must wait for the current load to finish'));
        return false;
      }
      if (!platform.canReloadByTimeRestriction()) {
        this.createToast(
          Toast.error(
            'Oops! Something went wrong',
            `You must wait ${MIN_HOURS_SINCE_LAST_IMPORT} hours since the last import`
          )
        );
        return false;
      }
      this.showReloadModal = true;
      this.selectedId = platform.id;
    },
    closeReloadModal() {
      this.selectedId = null;
      this.showReloadModal = false;
    },
    updatePlatformStatus(platformId) {
      const reloadedPlatform = this.items.find(platform => platform.id === platformId);
      reloadedPlatform?.reload();
    },
  },
};
</script>
