var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "router-link",
                  { attrs: { to: _vm.newPlatformRoute } },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                        attrs: { variant: "pill", color: "gray" },
                      },
                      [_vm._v(" + New platform ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.provider`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(
                                " " + _vm._s(item.platformProvider.name) + " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("status-pill", {
                                  attrs: {
                                    status: item.status.id,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.updated`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                item.lastImport
                                  ? _c("date-tooltip", {
                                      staticClass: "inline-flex",
                                      attrs: { date: item.lastImport },
                                    })
                                  : _c("p", [_vm._v("-")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showReloadModal
        ? _c("platform-reload-modal", {
            attrs: {
              "platform-id": _vm.selectedId,
              "reload-fn": _vm.reloadPlatformData,
            },
            on: {
              cancel: _vm.closeReloadModal,
              reload: _vm.updatePlatformStatus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }