import { PLATFORM_RESOURCE } from '@/services/keys';
import { api } from '.';

/**
 *
 * @param {Platform} platform
 * @param {QueryParamsBuilder} params
 * @return {Promise.<data:object>}
 */
export async function createPlatformConnection(platform) {
  const partialUrl = api.createUrl({
    [PLATFORM_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, platform.payload());
  return data.platform?.id;
}
