<template>
  <section id="platform-form" class="w-full">
    <section-header :is-loading="isLoading">
      <template #title>
        <breadcrumb class="flex flex-1" />
      </template>
    </section-header>

    <card>
      <sun-form @submit="save">
        <form-row>
          <div class="w-full lg:w-1/2 lg:pr-4">
            <sun-label-group text="Name">
              <sun-input
                :id="`${entity}-name`"
                v-model="platform.name"
                placeholder="My custom name"
                :text-error="i18nErrors.greaterThanThreeLessFiftyChars"
                :minlength="3"
                :maxlength="50"
                required
              />
            </sun-label-group>
          </div>
        </form-row>
        <form-row>
          <template #left>
            <sun-label-group text="Platform Provider">
              <asterix-async-select
                :id="`${entity}-platform-provider`"
                :value="platformProvider"
                add-hex-color="orange"
                label="name"
                name="platformProvider"
                placeholder="Platform Provider"
                required="required"
                :service="getPlatformProviders"
                track-by="id"
                :text-error="i18nErrors.selectOption"
                @change="onPlatformProviderChange($event.items)"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="API User Connection">
              <asterix-async-select
                :id="`${entity}-provider-config`"
                :key="configKey"
                :value="providerConfig"
                add-hex-color="orange"
                :disabled="isApiUserConnectionDisabled"
                label="name"
                name="providerConfig"
                placeholder="Select a new API User Connection"
                required="required"
                :service="getProviderConfigs"
                :text-error="i18nErrors.selectOption"
                track-by="id"
                @change="providerConfig = $event.items"
              />
            </sun-label-group>
          </template>
        </form-row>
        <div class="flex mt-10">
          <save-button :id="`${entity}-submit`" :loading="isLoading" />
        </div>
      </sun-form>
    </card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ERRORS } from '@/i18n/forms/errors';
import { SET_LOAD } from '@/store/modules/load/keys';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { Platform } from '@/entities/ThirdParty/Platform';
import { loadMixin } from '@/mixins/common/load';
import SectionHeader from '@/components/atoms/SectionHeader';
import SaveButton from '@/components/atoms/SaveButton';
import { createPlatformConnection } from '@/services/modules/ThirdParty/platforms/createPlatformConnection';
import { getPlatformProviders } from '@/services/modules/ThirdParty/platforms/getPlatformProviders';
import { CONTEXTS } from '@/model/shared/contexts';
import Card from '@/components/atoms/Card/Card';
import metaInfo from '@/mixins/common/metaInfo';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getProvidersConfigByProviderId } from '@/services/modules/ThirdParty/platforms/getProvidersConfigByProviderId';
import ProviderConfig from '@/entities/ThirdParty/ProviderConfig';
import { PlatformProvider } from '@/entities/ThirdParty/PlatformProvider';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { list as platformConnectionList } from '@/router/private/modules/thirdParty/platforms/connection/list';

export default {
  name: 'PlatformForm',
  components: {
    AsterixAsyncSelect,
    Breadcrumb,
    FormRow,
    Card,
    SectionHeader,
    SaveButton,
  },
  mixins: [activeClientMixin, loadMixin, metaInfo],
  data() {
    return {
      platform: new Platform(),
      entity: 'platform',
      i18nErrors: ERRORS.en,
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    configKey() {
      return this.platformProvider?.id || '0';
    },
    isApiUserConnectionDisabled() {
      return !this.platform.platformProvider.id;
    },
    platformProvider: {
      get() {
        return this.platform.platformProvider.id ? this.platform.platformProvider : null;
      },
      set(val) {
        this.platform.platformProvider = val || new PlatformProvider();
      },
    },
    providerConfig: {
      get() {
        return this.platform.providerConfig.id ? this.platform.providerConfig : null;
      },
      set(val) {
        this.platform.providerConfig = val || new ProviderConfig();
      },
    },
  },
  async beforeMount() {
    this.platform.client = this.activeClient;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    onActiveClientChange() {
      this.$router.replace({ name: `${CONTEXTS.THIRD_PARTY.baseUrl}-platforms` });
    },
    metaTitleReplacement() {
      return { platform: this.platform.name } || {};
    },
    getPlatformProviders,
    getProviderConfigs() {
      return getProvidersConfigByProviderId(this.activeClient.id, this.platformProvider.id);
    },
    onPlatformProviderChange(platformProvider) {
      this.platformProvider = platformProvider;
      this.platform.providerConfig = new ProviderConfig();
    },
    async save({ valid }) {
      if (valid) {
        try {
          this[SET_LOAD]();
          this.platform.id = await createPlatformConnection(this.platform);
          this.createToast(
            Toast.success('Platform connection created', 'The platform connection has been created successfully')
          );
          this.goToPlatformConnectionsList();
        } catch (error) {
          this.createToast(Toast.error('Platform connection not created', error.message));
          this.hasError = true;
        } finally {
          this[SET_LOAD](false);
        }
      }
    },
    goToPlatformConnectionsList() {
      this.$router.push({ name: platformConnectionList.name });
    },
  },
};
</script>
