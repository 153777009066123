var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "w-full", attrs: { id: "platform-form" } },
    [
      _c("section-header", {
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_c("breadcrumb", { staticClass: "flex flex-1" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "card",
        [
          _c(
            "sun-form",
            { on: { submit: _vm.save } },
            [
              _c("form-row", [
                _c(
                  "div",
                  { staticClass: "w-full lg:w-1/2 lg:pr-4" },
                  [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Name" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.entity}-name`,
                            placeholder: "My custom name",
                            "text-error":
                              _vm.i18nErrors.greaterThanThreeLessFiftyChars,
                            minlength: 3,
                            maxlength: 50,
                            required: "",
                          },
                          model: {
                            value: _vm.platform.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.platform, "name", $$v)
                            },
                            expression: "platform.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("form-row", {
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c(
                          "sun-label-group",
                          { attrs: { text: "Platform Provider" } },
                          [
                            _c("asterix-async-select", {
                              attrs: {
                                id: `${_vm.entity}-platform-provider`,
                                value: _vm.platformProvider,
                                "add-hex-color": "orange",
                                label: "name",
                                name: "platformProvider",
                                placeholder: "Platform Provider",
                                required: "required",
                                service: _vm.getPlatformProviders,
                                "track-by": "id",
                                "text-error": _vm.i18nErrors.selectOption,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onPlatformProviderChange(
                                    $event.items
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "right",
                    fn: function () {
                      return [
                        _c(
                          "sun-label-group",
                          { attrs: { text: "API User Connection" } },
                          [
                            _c("asterix-async-select", {
                              key: _vm.configKey,
                              attrs: {
                                id: `${_vm.entity}-provider-config`,
                                value: _vm.providerConfig,
                                "add-hex-color": "orange",
                                disabled: _vm.isApiUserConnectionDisabled,
                                label: "name",
                                name: "providerConfig",
                                placeholder: "Select a new API User Connection",
                                required: "required",
                                service: _vm.getProviderConfigs,
                                "text-error": _vm.i18nErrors.selectOption,
                                "track-by": "id",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.providerConfig = $event.items
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "flex mt-10" },
                [
                  _c("save-button", {
                    attrs: {
                      id: `${_vm.entity}-submit`,
                      loading: _vm.isLoading,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }