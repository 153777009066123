import Column from '@/model/shared/Column';

export const CONFIG = {
  headers: [
    new Column('NAME', 'name').setClass('font-bold'),
    new Column('STATUS', 'status'),
    new Column('PROVIDER', 'provider'),
    new Column('UPDATE', 'updated'),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
};
