import { api } from '.';
import { CLIENT_RESOURCE, PROVIDERS_CONFIG_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @return {Promise.<data:object>}
 */
export async function getActiveProviderConfigsByClientId(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [PROVIDERS_CONFIG_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
  });
  params.addFilter('status', '1');

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(thirdPartyEntityParser.parse(data)).build();
}
