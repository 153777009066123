import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getActiveProviderConfigsByClientId } from '@/services/modules/ThirdParty/platforms/getActiveProviderConfigsByClientId';

/**
 *
 * @param {String} clientId
 * @param {String} providerId
 * @return {Promise.<data:object>}
 */
export function getProvidersConfigByProviderId(clientId, providerId) {
  if (!providerId) throw new Error('You should provide a valid value for providerId');

  const params = new QueryParamsBuilder();
  params.addFilter('providerId', providerId);

  return getActiveProviderConfigsByClientId(clientId, params);
}
